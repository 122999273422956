<template>
    <label
        for="pageSizeSelector-pageSize"
        class="text-sm"
        :class="{
            'text-gray-300': theme === Theme?.Dark,
            'text-gray-400': theme === Theme?.Light,
        }"
        v-html="`${t('baseComponents.basePagination.pageSize')}: `"
    />
    <select
        id="pageSizeSelector-pageSize"
        data-spec-class="pageSize"
        class="ml-2 rounded border border-gray-400 text-sm"
        :class="{
            'text-gray-300': theme === Theme?.Dark,
            'text-gray-400': theme === Theme?.Light,
        }"
        :value="pageSize"
        @change="$emit('change', $event.target.value)"
    >
        <option
            v-for="pageSize in pageSizes"
            :key="pageSize"
            :value="pageSize"
            v-text="pageSize"
        />
    </select>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits } from 'vue';
import { Theme } from '../../types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

interface PageSizeSelectorProps {
    theme?: Theme;
    pageSize: number;
    pageSizes: number[];
}

withDefaults(defineProps<PageSizeSelectorProps>(), {
    pageSizes: [15, 30, 50, 100],
});

defineEmits(['change']);
</script>
