<template>
    <span
        :class="[classObject, !color ? classObjectTheme : '']"
        :style="[color ? styleObject : '']"
        data-spec-class="badge"
    >
        <slot />
    </span>
</template>

<script lang="ts" setup>
import { withDefaults, defineProps, computed } from 'vue';
import { BadgeSize, Theme } from '../types';
import { contrastColor } from '../utils/colorUtils';

interface Props {
    theme?: Theme;
    size?: BadgeSize;
    color?: string;
}

const props = withDefaults(defineProps<Props>(), {
    theme: Theme.Normal,
    size: BadgeSize.Small,
    color: undefined,
});

const classObject = computed(() => ({
    'inline-flex items-center rounded-full font-medium whitespace-nowrap': true,
    'px-2 py-0.5 text-xs': props.size === BadgeSize.Small,
    'px-3 py-0.5 text-base ': props.size === BadgeSize.Large,
}));

const classObjectTheme = computed(() => ({
    'bg-blue-400 text-gray-900': props.theme === Theme.Normal,
    'bg-gray-200 text-gray-900': props.theme === Theme.Secondary,
    'bg-red text-white': props.theme === Theme.Danger,
    'bg-purple-700 text-white': props.theme === Theme.Cta,
    'bg-orange-700 text-gray-900': props.theme === Theme.Warning,
    'bg-green-light text-gray-900': props.theme === Theme.Success,
}));

const styleObject = computed(() => {
    return { backgroundColor: props.color, color: contrastColor(props.color) };
});
</script>
