import { computed, reactive, ref } from 'vue';
import { Attribute, PageInformation, Theme, Ticket, TicketStatus } from '../../../types';
import { useCoreSetup } from './core';
import { useI18n } from 'vue-i18n';
import { useToast } from './toast';
import { useTicketStore } from '../stores/ticketStore';

export const NAME = 'ticket';

const ATTRIBUTES: Attribute[] = [
    {
        name: 'subject',
        isSortable: false,
        isSearchable: true,
        position: 1,
    },
    {
        name: 'id',
        isSortable: false,
        isSearchable: false,
        position: 2,
    },
    {
        name: 'created_at',
        isSortable: true,
        isSearchable: false,
        position: 3,
    },
    {
        name: 'updated_at',
        isSortable: true,
        isSearchable: false,
        position: 4,
    },
    {
        name: 'status',
        isSortable: false,
        isSearchable: false,
        position: 5,
    },
];

const search = ref('');
const sorting = ref('updated_at:desc');
const filters = ref({ status: TicketStatus.Open });
const formFields = reactive({
    topic: '',
    subject: '',
    description: '',
    file: null as File | null,
});

const resetFormFields = () => {
    formFields.topic = '';
    formFields.subject = '';
    formFields.description = '';
    formFields.file = null;
};

export function useTicketSetup() {
    const { buildAttributes, diffInWords } = useCoreSetup();
    const ticketStore = useTicketStore();

    const { addErrorToast } = useToast();
    const { t } = useI18n();

    const attributes = computed(() => buildAttributes(ATTRIBUTES, NAME));

    const pageUpdate = async ({ page, pageSize }: PageInformation, sourceType) => {
        try {
            await ticketStore.fetchPaginatedTickets(
                sourceType,
                page,
                pageSize ?? ticketStore.fetchPaginatedTicketsMeta?.per_page,
                [sorting.value],
                filters.value,
                search.value
            );
        } catch (error) {
            addErrorToast(t('errors.serverError.message'));
        }
    };

    function decorateStatusTheme(ticket: Ticket) {
        switch (ticket.status) {
            case TicketStatus.New:
                return Theme.Danger;
            case TicketStatus.Open:
                return Theme.Danger;
            case TicketStatus.Solved:
                return Theme.Secondary;
            case TicketStatus.Closed:
                return Theme.Secondary;
            case TicketStatus.Pending:
                return Theme.Success;
            default:
                return Theme.Secondary;
        }
    }

    function decorateStatusText(ticket: Ticket) {
        return t(`resources.ticket.enums.status.${ticket.status}`);
    }

    function decorateCreatedAt(ticket: Ticket) {
        return diffInWords(ticket.created_at);
    }

    function decorateUpdatedAt(ticket: Ticket) {
        return diffInWords(ticket.updated_at);
    }

    function toTicketById(id: number) {
        return { name: 'TicketPage', params: { id } };
    }

    return {
        attributes,
        search,
        sorting,
        filters,
        pageUpdate,
        decorateStatusTheme,
        decorateStatusText,
        decorateUpdatedAt,
        decorateCreatedAt,
        toTicketById,
    };
}

export function useTicket() {
    return { sorting, formFields, resetFormFields };
}
