export function setCookie(name: string, value: string, days?: number): void {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        cookieString += `; expires=${date.toUTCString()}`;
    }

    document.cookie = cookieString;
}

export function setCookieWithEndDate(name: string, value: string, endDate: Date | string): void {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    if (typeof endDate === 'string') {
        cookieString += `; expires=${new Date(endDate).toUTCString()}`;
    } else if (endDate instanceof Date) {
        cookieString += `; expires=${endDate.toUTCString()}`;
    }

    document.cookie = cookieString;
}

export function getCookie(name: string): string | null {
    const nameWithEqual = `${encodeURIComponent(name)}=`;
    const cookies = document.cookie.split(';');

    for (let cookie of cookies) {
        cookie = cookie.trim();
        if (cookie.startsWith(nameWithEqual)) {
            return decodeURIComponent(cookie.substring(nameWithEqual.length));
        }
    }

    return null;
}
