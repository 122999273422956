/* eslint-disable import/prefer-default-export */

import { ref, nextTick } from 'vue';

export function useCarousel() {
    const currentIndex = ref(0);
    const slideCount = ref(0);

    const updateSlideCount = async () => {
        await nextTick();
        const slides = document.querySelectorAll('.carousel-slide');
        slideCount.value = slides.length;
    };

    const next = async () => {
        await updateSlideCount();
        currentIndex.value = (currentIndex.value + 1) % slideCount.value;
    };

    const prev = async () => {
        await updateSlideCount();
        currentIndex.value =
            currentIndex.value === 0 ? slideCount.value - 1 : currentIndex.value - 1;
    };

    const goToSlide = (index) => {
        currentIndex.value = index;
    };

    return {
        currentIndex,
        slideCount,
        updateSlideCount,
        next,
        prev,
        goToSlide,
    };
}
