<template>
    <BaseCarouselY
        ref="carouselRef"
        :has-controls="false"
        data-spec-class="badge-carousel"
    >
        <BaseCarouselSlide
            v-for="badgeCarouselItem in badgeCarouselItems"
            :key="badgeCarouselItem.id"
        >
            <BaseBadge
                :theme="badgeCarouselItem.theme"
                :data-spec-value="`badge-carousel__badge--${badgeCarouselItem.id}`"
                :size="size"
            >
                {{ badgeCarouselItem.text }}
            </BaseBadge>
        </BaseCarouselSlide>
    </BaseCarouselY>
</template>

<script setup lang="ts">
import { defineProps, onMounted, ref, defineExpose } from 'vue';
import { BadgeSize, BadgeCarouselItem } from '../types';

const badgeCarouselItems = ref([] as BadgeCarouselItem[]);

const carouselRef = ref(null);

const props = defineProps<{
    badgeCarouselItem: BadgeCarouselItem;
    size?: BadgeSize;
}>();

const addBadgeCarouselItem = (badgeCarouselItem: BadgeCarouselItem) => {
    badgeCarouselItems.value.push(badgeCarouselItem);
    carouselRef.value.next();
};

onMounted(() => {
    badgeCarouselItems.value.push(props.badgeCarouselItem);
});

defineExpose({ addBadgeCarouselItem });
</script>
