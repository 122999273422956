export function getQueryParametersWithout(paramsToRemove) {
    const urlParams = new URLSearchParams(window.location.search);

    paramsToRemove.forEach((param) => {
        urlParams.delete(param);
    });

    return urlParams.toString();
}

export function getQueryParams(delimiter = '|') {
    return new URLSearchParams(window.location.search).toString().split('&').join(delimiter);
}

export function getAllUrlParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const returnParams = {};

    urlParams.forEach((value, key) => {
        const arraySyntaxMatch = /^(?<arrayKey>[A-Za-z]+)\[(?<arrayValue>\d+)\]$/.exec(key);
        if (arraySyntaxMatch !== null) {
            if (returnParams[arraySyntaxMatch.groups.arrayKey] === undefined) {
                returnParams[arraySyntaxMatch.groups.arrayKey] = {};
            }
            returnParams[arraySyntaxMatch.groups.arrayKey][arraySyntaxMatch.groups.arrayValue] =
                value;
            return;
        }
        returnParams[key] = value;
    });

    return returnParams;
}

export function getUrlParam(key) {
    return getAllUrlParams()[key];
}

export function getLocaleFromUrl() {
    return window.location.href.split('/')[3];
}
