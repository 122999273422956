<template>
    <MenuItem :data-spec-class="dataSpecClass">
        <BaseLink
            v-bind="$attrs"
            :class="classObjectMenuItem"
            :to="to"
            data-spec-class="dropdown_menu_item__link"
        >
            <slot />
        </BaseLink>
    </MenuItem>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, computed } from 'vue';
import { MenuItem } from '@headlessui/vue';
import { RouteLocationNormalized } from 'vue-router';

interface Props {
    to?: RouteLocationNormalized;
    dataSpecClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
    to: undefined,
    dataSpecClass: 'dropdown_menu_item',
});

const classObjectMenuItem = computed(() => ({
    'block px-4 py-2 text-sm': true,
    'hover:bg-blue-100 text-gray-600 hover:text-gray-600': true,
}));
</script>

<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>
