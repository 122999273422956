/* eslint-disable import/prefer-default-export */

import { computed, reactive, ref } from 'vue';
import {
    MarketingBanner,
    MarketingBannerDisplayLocation,
    MarketingBannerDisplayLocationType,
    SortDirection,
} from '../../../types';
import { getCookie, setCookieWithEndDate } from '../../../utils/cookieUtils';
import { sortArrayByField } from '../../../utils/objectUtils';

const marketingBannerDismissedCookieKey = 'marketingBannerDismissed';
const hasNotDismissed = ref(true);

export function useMarketingBanner(
    marketingBanners = [] as MarketingBanner[],
    displayLocationType = null as MarketingBannerDisplayLocationType | null
) {
    const hasBannersForThisDisplayLocation = (banner: MarketingBanner) => {
        if (!banner) {
            return false;
        }

        return banner.display_locations
            .map(
                (bannerDisplayLocation: MarketingBannerDisplayLocation) => bannerDisplayLocation.key
            )
            .some(
                (bannerDisplayLocation: MarketingBannerDisplayLocationType) =>
                    bannerDisplayLocation === displayLocationType
            );
    };

    const banner = computed(() => {
        const bannersForThisDisplayLocation = marketingBanners.filter((banner) => {
            return hasBannersForThisDisplayLocation(banner);
        });

        if (bannersForThisDisplayLocation.length === 1) {
            return bannersForThisDisplayLocation[0];
        }

        const bannersForThisDisplayLocationSorted = sortArrayByField(
            bannersForThisDisplayLocation,
            'publish_from',
            SortDirection.Desc
        );
        return bannersForThisDisplayLocationSorted[0] || undefined;
    });

    const hasNotDismissedFromCookie = () => {
        if (!banner.value) {
            return;
        }
        return getCookie(marketingBannerDismissedCookieKey) !== banner.value.slug;
    };

    hasNotDismissed.value = hasNotDismissedFromCookie();

    const isBannerVisible = computed(() => {
        if (!banner.value) {
            return false;
        }

        const hasThisDisplayLocation = hasBannersForThisDisplayLocation(banner.value);
        return hasThisDisplayLocation && hasNotDismissed.value;
    });

    const onClose = (banner: MarketingBanner) => {
        setCookieWithEndDate(marketingBannerDismissedCookieKey, banner.slug, banner.publish_to);
        hasNotDismissed.value = hasNotDismissedFromCookie();
    };

    return {
        banner,
        isBannerVisible,
        onClose,
    };
}
