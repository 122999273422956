<template>
    <BaseCard
        :theme="Theme.Dark"
        class="relative lg:!px-16 lg:!py-12"
        data-spec-class="thanks-card"
    >
        <div class="relative z-20">
            <h1 class="text-xl lg:text-2xl">
                {{ t('messages.thanks.title') }}
            </h1>
            <div class="lg:text-xl">
                {{ t('messages.thanks.description') }}
            </div>

            <img
                class="mt-6 h-8"
                src="/img/23M-Logo.svg"
                alt="23M Logo"
            />
        </div>
        <div class="thanks-gradient"></div>
        <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
            <button
                @click="emits('close')"
                type="button"
                class="focus:ring-indigo-500 text-white focus:ring-2"
                data-spec-class="thanks-card__close"
            >
                <span class="sr-only">Close</span>
                <XCircleIcon
                    class="h-8 w-8"
                    aria-hidden="true"
                />
            </button>
        </div>
    </BaseCard>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import { Theme } from '../types';
import { XCircleIcon } from '@heroicons/vue/24/outline';

const { t } = useI18n();

const emits = defineEmits(['close']);

defineProps<{
    isOpen: boolean;
}>();
</script>

<style scoped>
.thanks-gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 400px;
    mask-position: 0% 50%;
    mask-image: url('/img/curve-header.svg');
    mask-size: cover;
}
.thanks-gradient::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('/img/gradient.jpg');
    background-position: 100% 75%;
    animation: background-move ease-out 60s infinite;
}
@keyframes background-move {
    16% {
        background-position: 75% 0;
    }
    33% {
        background-position: 25% 50%;
    }
    50% {
        background-position: 100% 25%;
    }
    67% {
        background-position: 75% 100%;
    }
    83% {
        background-position: 0 0;
    }
}

@media (max-width: 1024px) {
    .thanks-gradient {
        width: 150px;
    }
}
</style>
