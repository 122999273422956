import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import axios from '../../../axios';
import {
    FetchPaginatedTicketsValidationError,
    Ticket,
    ObjectResponse,
    PaginationMeta,
    CreateTicketValidationError,
    uploadFileValidationError,
    TicketFile,
    TicketFetchingSourceType,
    updateTicketError,
} from '../../../types';
import { arrayToIndexObject, sortArrayBySorting } from '../../../utils/objectUtils';
import { useTicket } from '../composables/ticket';

const { sorting } = useTicket();

const endpointUrl = '/api/v1/tickets';

export const useTicketStore = defineStore('ticket', {
    state: () => ({
        tickets: {} as { [key: string]: Ticket },
        files: {} as { [key: string]: TicketFile },
        comments: {} as { [key: string]: Comment },

        isFetchingPaginatedTickets: false,
        isFetchingTicket: false,
        sourceType: null as TicketFetchingSourceType | null,
        fetchPaginatedTicketsValidationError: null as FetchPaginatedTicketsValidationError | null,
        fetchPaginatedTicketsMeta: null as PaginationMeta | null,

        isCreatingTicket: false,
        createTicketValidationError: null as CreateTicketValidationError | null,

        isUpdatingTicket: false,
        updateTicketError: null as updateTicketError | null,

        isUploadingFile: false,
        uploadFileValidationError: null as uploadFileValidationError | null,

        isDestroyingFile: false,
    }),
    actions: {
        assignTicket(ticket: Ticket) {
            const ticketIndexObject = arrayToIndexObject([ticket]);
            Object.assign(this.tickets, ticketIndexObject);
        },
        assignFile(file: TicketFile) {
            const fileIndexObject = arrayToIndexObject([file], 'token');
            Object.assign(this.files, fileIndexObject);
        },
        async fetchPaginatedTickets(
            sourceType,
            page = 1,
            itemsPerPage = 15,
            sortings = [] as string[],
            filters = {} as { [key: string]: string },
            search = ''
        ) {
            try {
                this.isFetchingPaginatedTickets = true;
                const response: AxiosResponse<ObjectResponse> = await axios.get(endpointUrl, {
                    params: {
                        page,
                        itemsPerPage,
                        sortings,
                        filters,
                        search,
                    },
                });

                if (response.data.status === 422) {
                    this.fetchPaginatedTicketsValidationError = response.data.errors;
                } else {
                    this.fetchPaginatedTicketsValidationError = null;
                    const tickets = response.data.data as Ticket[];
                    this.tickets = arrayToIndexObject(tickets);
                    this.fetchPaginatedTicketsMeta = response.data.meta;
                    this.sourceType = sourceType;
                }
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isFetchingPaginatedTickets = false;
            }
        },
        async fetchTicket(id: number) {
            try {
                this.isFetchingTicket = true;
                const response: AxiosResponse<ObjectResponse> = await axios.get(
                    `${endpointUrl}/${id}`
                );
                const ticket = response.data.data as Ticket;
                this.assignTicket(ticket);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isFetchingTicket = false;
            }
        },
        async getOrFetchTicket(id: number) {
            const ticket = this.getTicket(id);
            if (!ticket) {
                await this.fetchTicket(id);
            }
        },
        async createTicket(fields) {
            try {
                this.createTicketValidationError = null;
                this.isCreatingTicket = true;
                const response: AxiosResponse<ObjectResponse> = await axios.post(
                    `${endpointUrl}`,
                    fields
                );

                if (response.data.status === 422) {
                    this.createTicketValidationError = response.data.errors;
                } else {
                    const newTicket = response.data.data as Ticket;
                    this.assignTicket(newTicket);

                    if (this.fetchPaginatedTicketsMeta && this.getTickets().length) {
                        if (
                            Number(this.getTickets().length) >
                            Number(this.fetchPaginatedTicketsMeta?.per_page)
                        ) {
                            const tickets = this.getTickets();
                            tickets.pop();
                            this.tickets = arrayToIndexObject(tickets);

                            this.fetchPaginatedTicketsMeta.total =
                                this.fetchPaginatedTicketsMeta.total + 1;
                        }
                    }

                    this.files = {};
                    return Promise.resolve(newTicket);
                }
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isCreatingTicket = false;
            }
        },

        async updateTicket(ticketId, fields) {
            try {
                this.updateTicketError = null;
                this.isUpdatingTicket = true;
                const response: AxiosResponse<ObjectResponse> = await axios.put(
                    `${endpointUrl}/${ticketId}`,
                    fields
                );

                if (response.data.status === 422) {
                    this.updateTicketError = response.data.errors;
                } else {
                    const updatedTicket = response.data.data as Ticket;
                    this.assignTicket(updatedTicket);

                    this.files = {};
                    return Promise.resolve();
                }
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isUpdatingTicket = false;
            }
        },
        async uploadFile(file) {
            try {
                this.uploadFileValidationError = null;
                this.isUploadingFile = true;

                const formData = new FormData();
                formData.append('file', file);
                const response: AxiosResponse<ObjectResponse> = await axios.post(
                    `${endpointUrl}/files`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.data.status === 422) {
                    this.uploadFileValidationError = response.data.errors;
                } else {
                    this.assignFile(response.data.data as TicketFile);
                    return Promise.resolve();
                }
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isUploadingFile = false;
            }
        },
        async destroyUploadedFile(file: TicketFile) {
            try {
                this.isDestroyingFile = true;

                const token = file.token;
                await axios.delete(`${endpointUrl}/files/${token}`);
                delete this.files[token];

                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isDestroyingFile = false;
            }
        },
    },
    getters: {
        getTickets(state) {
            const tickets = Object.values(state.tickets);
            return () => sortArrayBySorting(tickets, sorting.value);
        },
        getTicket(state) {
            return (id: number) => state.tickets[id];
        },
        getFiles(state) {
            return Object.values(state.files);
        },
    },
});
