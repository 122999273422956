export default {
    baseComponents: {
        basePagination: {
            previous: 'Zurück',
            next: 'Nächste',
            metaInfo: `
            Zeige <span class="font-medium">{itemsFrom}</span>
            bis <span class="font-medium">{itemsTo}</span>
            von <span class="font-medium">{itemsTotal}</span>
            Ergebnissen`,
            pageSize: 'Ergebnisse pro Seite',
        },
    },
    general: {
        period: 'Zeitraum',
        from: 'Von',
        to: 'Bis',
        day: 'Tag | Tage',
        today: 'Heute',
        week: '{count} Woche | {count} Wochen',
        month: '{count} Monat | {count} Monate',
        noData: 'Noch keine Daten vorhanden',
        isSelfManaged: 'Selbstverwaltet',
        general: 'Allgemein ',
        services: 'Services',
        base: 'Basis',
        kilowattHour: 'Kilowattstunde | Kilowattstunden',
        all: 'Alle',
        watts: 'W',
        noResultsFound: 'Keine Ergebnisse gefunden',
        noResultsFoundDescription: 'Setzen Sie Ihre Filter zurück und versuchen Sie es erneut.',
        inclusive: 'inklusive',
        cpu: 'CPU | CPUs',
        diffInSeconds: 'Vor {count} Sekunde | Vor {count} Sekunden',
        diffInMinutes: 'Vor {count} Minute | Vor {count} Minuten',
        diffInHours: 'Vor {count} Stunde | Vor {count} Stunden',
        diffInDays: 'Vor {count} Tag | Vor {count} Tagen',
        diffInWeeks: 'Vor {count} Woche | Vor {count} Wochen',
        diffInMonths: 'Vor {count} Monat | Vor {count} Monaten',
        timezones: {
            cet: 'MEZ',
            cest: 'MESZ',
        },
        termsAndPrivacy:
            'Es gelten unsere <a class="text-blue-700 hover:text-blue-600 cursor-pointer underline" href="{termsUrl}" target="_blank">Allgemeinen Geschäftsbedingungen</a>. Bitte beachten Sie unsere <a class="text-blue-700 hover:text-blue-600 cursor-pointer underline" href="{privacyUrl}" target="_blank">Datenschutzerklärung</a>.',
    },
    actions: {
        addEntry: 'Eintrag hinzufügen',
        addFile: 'Datei hinzufügen',
        add: 'Hinzufügen',
        create: 'Anlegen',
        show: 'Anzeigen',
        showAll: 'Alle anzeigen',
        destroy: 'Löschen',
        edit: 'Bearbeiten',
        download: 'Download',
        confirm: 'Bestätigen',
        cancel: 'Abbrechen',
        save: 'Speichern',
        back: 'Zurück',
        next: 'Weiter',
        complete: 'Abschließen',
        adjust: 'Anpassen',
        logout: 'Abmelden',
        backToLogin: 'Zurück zum Login',
        editResource: '{resource} bearbeiten',
        createResource: '{resource} anlegen',
        feedback: 'Feedback',
        deactivate: 'Deaktivieren',
        activate: 'Aktivieren',
        moreInfo: 'Mehr Infos',
        clearFilters: 'Filter zurücksetzen',
        comment: 'Kommentieren',
    },
    dialogs: {
        maintenance: {
            title: 'Es finden derzeit Wartungsarbeiten statt',
            message:
                'Ihre Aktion konnte nicht ausgeführt werden. Bitte entschuldigen Sie die Unannehmlichkeiten. Die Seite wird automatisch neu geladen, wenn die Wartungsarbeiten abgeschlossen sind.',
            countdown: 'Nächster Versuch in {counter} Sekunden.',
        },
        sessionExpiration: {
            title: 'Ihre Sitzung ist abgelaufen',
            message: 'Bitte melden Sie sich erneut an.',
        },
        confirmation: {
            title: 'Sind Sie sich sicher?',
            message: 'Möchten Sie wirklich fortfahren?',
            destroyEntryMessage: 'Möchten Sie diesen Eintrag wirklich löschen?',
        },
        feedback: {
            title: 'Wie können wir unseren Service verbessern?',
        },
        confirmPassword: {
            title: 'Bestätigen Sie Ihr Passwort',
            toast: 'Passwort erfolgreich bestätigt',
        },
    },
    errors: {
        serverError: {
            message: 'Etwas ist schief gelaufen!',
            description:
                'Bitte versuchen Sie es später noch einmal oder melden Sie sich bei unserem Support.',
        },
        unconfirmedPassword: {
            message: 'Password wurde nicht bestätigt!',
        },
    },
    globalMessages: {
        configuratorMaintenance: {
            message: 'Wartungsarbeiten',
            description:
                'Es finden derzeit Wartungsarbeiten am Konfigurator statt. Dieser steht Ihnen in kürze wieder zur verfügung.',
        },
    },
    validations: {
        required: 'Dies ist ein Pflichtfeld',
        requiredIf: 'Dies ist ein Pflichtfeld',
        minLength: 'Mindestens {min} Zeichen',
        email: 'Die eingegebene Mail-Adresse ist ungültig',
        ipV4: 'Die eingegebene IPv4-Adresse ist ungültig',
        between: 'Bitte geben Sie einen Wert zwischen {min} und {max} ein',
        sameAs: 'Muss mit "{otherName}" übereinstimmen',
        fileSize50: 'Die Datei darf maximal 50 MB groß sein',
        mustBeConfirmed: 'Dieses Feld muss bestätigt werden',
    },
    links: {
        privacy: 'Datenschutz',
        imprint: 'Impressum',
        dedicatedServer: 'Dedicated Server',
        colocation: 'Colocation',
        cloudServer: 'Cloud Server',
        s3Storage: 'S3-Storage',
    },
};
