<template>
    <BaseField
        :label="label"
        :hint="hint"
        :is-optional="isOptional"
        :name="name"
        :error-messages="errorMessagesTotal"
        :data-spec-class="dataSpecClass"
        :custom-theme-base="customThemeBase"
    >
        <div :class="['relative rounded-md', wrapperClassAddition]">
            <input
                v-bind="$attrs"
                :type="type"
                :name="name"
                :class="classObject"
                :placeholder="placeholder"
                :disabled="isDisabled"
                :value="modelValue"
                ref="input"
                @input="$emit('update:modelValue', $event.target.value)"
                @blur="hasAutoDirty ? vuelidateField?.$touch() : () => {}"
                :data-spec-class="`${dataSpecClass}__input`"
            />

            <div
                v-if="errorMessagesTotal.length || !!$slots.addOn || isClearable"
                class="absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <ExclamationCircleIcon
                    v-if="errorMessagesTotal.length"
                    class="h-5 w-5 text-red"
                    aria-hidden="true"
                />

                <span
                    v-if="isClearable && modelValue"
                    @click="
                        $emit('update:modelValue', '');
                        $emit('clear');
                    "
                    data-spec-class="field-input__clear"
                >
                    <XCircleIcon class="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-400" />
                </span>

                <span
                    v-if="!!$slots.addOn"
                    class="ml-1 text-gray-500 sm:text-sm"
                >
                    <slot name="addOn" />
                </span>
            </div>
        </div>
    </BaseField>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits, computed, ref, defineExpose } from 'vue';
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid';
import { FormTheme, TextFieldType, Theme } from '../types';
import BaseField from './BaseField.vue';

defineEmits(['update:modelValue', 'clear']);

const input = ref(null);

function focus() {
    input.value.focus();
}

function select() {
    input.value.select();
}

defineExpose({ focus, select });

interface Props {
    name: string;
    label?: string;
    hint?: string;
    placeholder?: string;
    isOptional?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    type?: TextFieldType;
    modelValue?: string;
    vuelidateField?: any;
    errorMessages?: string[];
    wrapperClassAddition?: string;
    customThemeBase?: FormTheme | string;
    customThemeNormal?: FormTheme | string;
    dataSpecClass?: string;
    hasAutoDirty?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    label: '',
    hint: '',
    placeholder: '',
    isOptional: false,
    isDisabled: false,
    isClearable: false,
    type: TextFieldType.Text,
    modelValue: undefined,
    vuelidateField: undefined,
    errorMessages: [],
    wrapperClassAddition: undefined,
    customThemeBase: undefined,
    customThemeNormal: undefined,
    dataSpecClass: 'field-input',
    hasAutoDirty: true,
});

const vuelidateErrors = computed(() => {
    if (props.vuelidateField?.$errors.length) {
        return props.vuelidateField.$errors.map((error) => error.$message);
    }

    return [];
});

const errorMessagesTotal = computed(() => [...vuelidateErrors.value, ...props.errorMessages]);

const theme = computed(() => {
    if (props.isDisabled) {
        return Theme.Disabled;
    }
    if (errorMessagesTotal.value.length) {
        return Theme.Danger;
    }

    return Theme.Normal;
});

const themeBase = computed(() => {
    if (props.customThemeBase === FormTheme.Website) {
        return 'w-full rounded p-4 text-dark';
    }
    if (typeof props.customThemeBase === 'string') {
        return props.customThemeBase;
    }

    return 'block w-full rounded-md shadow-sm sm:text-sm';
});

const themeNormal = computed(() => {
    if (typeof props.customThemeNormal === 'string') {
        return props.customThemeNormal;
    }
    return 'border-gray-300 focus:border-blue-500 focus:ring-blue-500 placeholder-gray-300';
});

const classObject = computed(() => ({
    [themeBase.value]: true,
    [themeNormal.value]: theme.value === Theme.Normal,
    'border-red-light pr-10 text-red-dark placeholder-red-light focus:border-red focus:outline-none focus:ring-red':
        theme.value === Theme.Danger,
    'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-500':
        theme.value === Theme.Disabled,
}));
</script>

<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>
