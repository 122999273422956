<template>
    <div
        v-if="isBannerVisible"
        class="gradient relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-900 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
        data-spec-class="the-marketing-banner"
    >
        <a
            :href="banner.cta_url.url"
            class="flex flex-wrap items-center gap-x-3 gap-y-2 text-white"
            data-spec-class="the-marketing-banner__cta"
        >
            <div v-html="banner.content"></div>
            <strong class="font-bold underline">
                {{ banner.cta_button }}
                <span aria-hidden="true">&rarr;</span>
            </strong>
        </a>

        <div class="flex flex-1 justify-end">
            <button
                type="button"
                class="-m-3 p-3 text-white focus-visible:outline-offset-[-4px]"
                @click="onClose(banner)"
                data-spec-class="the-marketing-banner__close"
            >
                <span class="sr-only">Dismiss</span>
                <XMarkIcon class="h-5 w-5"></XMarkIcon>
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { MarketingBannerDisplayLocationType } from '../../../types';
import { defineProps } from 'vue';
import { useMarketingBanner } from '../../your/composables/marketingBanner';

const props = defineProps({
    marketingBanners: Object,
    displayLocationType: MarketingBannerDisplayLocationType,
});

const { banner, isBannerVisible, onClose } = useMarketingBanner(
    props.marketingBanners,
    props.displayLocationType
);
</script>

<style scoped>
.gradient {
    background-image: url('../../../../../public/img/header-mobile.jpg');
    background-size: cover;
}
</style>
