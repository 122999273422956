<template>
    <div class="relative w-full overflow-hidden">
        <div
            class="flex transition-transform duration-500 ease-in-out"
            :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
        >
            <slot></slot>
        </div>

        <button
            v-if="props.hasControls"
            @click="prev"
            class="absolute left-4 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-full bg-white/20 text-white backdrop-blur-sm transition-colors hover:bg-white/30"
        >
            <svg
                class="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                />
            </svg>
        </button>

        <button
            v-if="props.hasControls"
            @click="next"
            class="absolute right-4 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-full bg-white/20 text-white backdrop-blur-sm transition-colors hover:bg-white/30"
        >
            <svg
                class="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                />
            </svg>
        </button>

        <div
            v-if="props.hasControls"
            class="absolute bottom-4 left-1/2 flex -translate-x-1/2 space-x-2"
        >
            <button
                v-for="(_, index) in slideCount"
                :key="index"
                @click="goToSlide(index)"
                :class="[
                    'h-2.5 w-2.5 rounded-full transition-all duration-300',
                    currentIndex === index ? 'scale-125 bg-white' : 'bg-white/50',
                ]"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, defineProps, defineExpose } from 'vue';

import { useCarousel } from '../composables/carousel';
const { prev, next, goToSlide, currentIndex, slideCount, updateSlideCount } = useCarousel();

interface Props {
    hasControls?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    hasControls: true,
});

defineExpose({ prev, next });

onMounted(async () => {
    updateSlideCount();
});
</script>
