<template>
    <button
        type="button"
        :class="classObject"
    >
        <slot></slot>
    </button>
</template>

<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue';
import { Functional } from '../types';

interface Props {
    type?: Functional;
}

const props = withDefaults(defineProps<Props>(), {
    type: Functional.Info,
});

const classObject = computed(() => ({
    'rounded-md px-2 py-1.5 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2': true,
    'text-blue-800 hover:bg-blue-400 focus:ring-blue-600 focus:ring-offset-blue-400':
        props.type === Functional.Info,
}));
</script>
