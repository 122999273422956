<template>
    <component
        :is="cardTag"
        :class="classObject"
        :to="to"
        data-spec-class="card"
    >
        <slot name="avatar" />
        <div class="min-w-0 flex-1">
            <slot />
        </div>

        <div
            v-if="$slots.action"
            class="mt-1 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center"
        >
            <slot name="action" />
        </div>
    </component>
</template>

<script lang="ts" setup>
import { withDefaults, defineProps, computed } from 'vue';
import { Theme } from '../types';
import { RouteLocationNormalized, RouterLink } from 'vue-router';

interface Props {
    tag?: string;
    theme?: Theme;
    to?: RouteLocationNormalized;
}

const props = withDefaults(defineProps<Props>(), {
    tag: 'div',
    theme: Theme.Normal,
    to: undefined,
});

const cardTag = computed(() => {
    if (props.to) {
        return RouterLink;
    }

    return props.tag;
});

const classObject = computed(() => ({
    'relative flex space-x-3 rounded border border-gray-200 px-6 py-5': true,
    'bg-white text-gray-900': props.theme === Theme.Normal,
    'bg-gray-800 text-white': props.theme === Theme.Dark,
    'relative transition duration-300 overflow-y-hidden cursor-pointer hover:outline-none hover:ring-2 hover:ring-blue-500 hover:ring-offset-1':
        props.to,
}));
</script>
