(function () {
    if (typeof globalThis === 'object') return;

    globalThis = (function () {
        // For browsers and workers
        if (typeof self !== 'undefined') return self;
        // For Node.js
        if (typeof global !== 'undefined') return global;
        // For browsers without self
        if (typeof window !== 'undefined') return window;
        // Last resort
        return Function('return this')();
    })();
})();
