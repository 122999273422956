export default {
    components: {
        TheLocaleSwitcher: {
            locales: {
                de: 'deutsch',
                en: 'english',
            },
            toast: 'Language changed',
        },
    },
    pages: {
        DashboardPage: {
            title: 'Dashboard',
            statusTeaser: {
                title: 'Statuspage',
                content: 'Information on future and ongoing maintenance.',
                action: 'Open page',
            },
            helpTeaser: {
                title: 'Knowledge base',
                content: 'Further information and answers to frequently asked questions.',
                action: 'Open page',
            },
            smokePingTeaser: {
                title: 'SmokePing',
                content: 'Check the latency of our network and measure its performance.',
                action: 'Open page',
            },
            contactTeaser: {
                title: 'Contacts',
                phone: 'Phone',
                mail: 'Mail',
                emergency: 'Emergency',
            },
            serviceTeaser: {
                title: 'Business hours',
                mondayToThursday: 'Mon. - Thu.',
                mondayToThursdayOpeningHours: '8:00 am - 5:00 pm',
                friday: 'Fri.',
                fridayOpeningHours: '8:00 am - 3:30 pm',
                info: 'All times are in German local time ({timeZone} [{utcDifference}]).',
            },
            newsPostsSection: {
                title: 'Recent @.lower:pages.NewsPostIndexPage.title',
            },
            ticketSection: {
                title: 'Open tickets',
            },
            helpfulLinksSection: {
                title: 'Helpful links',
            },
        },
        CustomerPage: {
            title: 'Customer data',
            breadcrumb: '@:pages.CustomerPage.title',
            contactSection: {
                inactiveSwitch: 'Deactivated @.lower:resources.contact.name',
            },
        },
        OrderIndexPage: {
            title: 'Orders',
            breadcrumb: '@:pages.OrderIndexPage.title',
        },
        OrderPage: {
            title: 'Order: {number}',
            breadcrumb: '{number}',
            offerSection: {
                title: 'Your personalised offer',
                body: 'By clicking on "Accept", you confirm the offer and finalise the binding order. If you select "Reject", you can tell us a reason or your change requests so that we can adjust the offer accordingly.',
                actions: {
                    approve: 'Accept ...',
                    reject: 'Reject ...',
                },
                approveDialog: {
                    title: 'Accept offer?',
                    body: 'By accepting this offer, you are placing a binding and chargeable order. Please check all details again before finalising your order.',
                    action: 'Accept with costs',
                    successMessage: 'Offer was accepted.',
                },
                rejectDialog: {
                    title: 'Reject offer?',
                    body: 'Please let us know why you reject the offer or what changes you would like to see. This will enable us to respond even better to your requirements.',
                    fieldLabel: 'Reason for the rejection',
                    fieldPlaceholder: 'I reject the offer because ...',
                    action: 'Reject definitively',
                    successMessage: 'Offer was rejected.',
                },
            },
            type: {
                s3: {
                    managementInterfaceSection: {
                        title: 'Management interface',
                        groupName: 'Group Name: Customers',
                        incompleteSetUp:
                            'Access to the management interface is not yet fully set up. Please contact our support team.',
                    },
                    endpointSection: {
                        title: 'S3 endpoints (via HTTP or HTTPS)',
                        hint: 'The S3 access data can be found in the administration interface (the link under your user ID) under your user name ("Security Credentials"). You can then manage buckets and access keys via the interface.',
                    },
                },
                cloud: {
                    managementInterfaceSection: {
                        title: 'Management interface',
                        hint: 'The button takes you to the administration interface where you can manage your computing instances. To do this, use the specified access data or request your password once.',
                    },
                },
            },
        },
        IpUsageIndexPage: {
            title: 'IPAM',
            breadcrumb: '@:pages.IpUsageIndexPage.title',
        },
        IpUsageNetworkPage: {
            title: 'IP network',
            breadcrumb: 'IP network',
        },
        OrderWanPage: {
            title: 'WAN-Traffic',
            breadcrumb: '@:pages.OrderWanPage.title',
        },
        OrderLanPage: {
            title: 'LAN-Traffic',
            breadcrumb: '@:pages.OrderLanPage.title',
        },
        OrderPowerPage: {
            title: 'Power consumption',
            breadcrumb: '@:pages.OrderPowerPage.title',
        },
        DeviceUsageIndexPage: {
            title: 'Server',
            breadcrumb: '@:pages.DeviceUsageIndexPage.title',
        },
        RentUsageIndexPage: {
            title: 'Rentals',
            breadcrumb: '@:pages.RentUsageIndexPage.title',
        },
        ColocationUsageIndexPage: {
            title: 'Colocation',
            breadcrumb: '@:pages.ColocationUsageIndexPage.title',
        },
        InvoiceIndexPage: {
            title: 'Invoices',
            breadcrumb: '@:pages.InvoiceIndexPage.title',
        },
        TicketIndexPage: {
            title: 'Tickets',
            breadcrumb: '@:pages.TicketIndexPage.title',
        },
        SettingsAccountPage: {
            title: 'Settings',
            breadcrumb: '@:pages.SettingsAccountPage.title',
            twoFactorAuthenticationDialog: {
                title: 'Activate two factor authentication',
                step1Label: 'Scan QR code',
                step1Description:
                    'Use an authentication app such as <a target="_blank" class="text-blue-700 hover:text-blue-600 hover:underline cursor-pointer" href="https://authy.com/"> Authy </a>, <a target="_blank" class="text-blue-700 hover:text-blue-600 hover:underline cursor-pointer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=de&gl=US"> Google Authenticator </a> or a browser extension such as <a target="_blank" class="text-blue-700 hover:text-blue-600 hover:underline cursor-pointer" href="https://1password.com/downloads/browser-extension/">1Password</a> to scan.',
                confirmationCodeField: 'Confirmation code',
                step2Label: 'Enter confirmation code',
                step2Description: 'Enter the code from the authentication app for confirmation.',
                step3Label: 'Save recovery codes',
                step3Description:
                    'Save your recovery codes in a safe place so that you can regain access to your account if you lose your authentication app.',
            },
        },
        NewsPostIndexPage: {
            title: 'News',
            breadcrumb: '@:pages.NewsPostIndexPage.title',
        },
        TicketPage: {
            breadcrumb: '{id}',
            ticketCreated: 'created @:resources.ticket.name',
            deletedUser: 'Deleted user',
            commented: 'commented',
            avatarAlt: 'Avatar of {name}',
            ticketSolved: 'Ticket solved.',
            ticketClosedAlert:
                'This ticket has already been closed and can no longer be processed. If you have any further questions, you are welcome to create a follow-up ticket.',
            followUpTicketDescription:
                'This is a follow-up ticket to ticket #{ticketId}, "{subject}".',
            createFollowUpTicket: 'Create follow-up ticket',
        },
    },
    resources: {
        address: {
            name: 'Address | Addresses',
            attributes: {
                street: 'Street',
                number: 'House number',
                zip: 'Zip code',
                city: 'City',
                additional: 'Additional',
                country: 'Country',
            },
            emptyState: {
                title: 'Create @.lower:resources.address.name',
            },
        },
        country: {
            name: 'Land | Länder',
            attributes: {
                code: 'Ländercode',
                name: 'Name',
            },
        },
        bankAccount: {
            name: 'Bank account | Bank accounts',
            emptyState: {
                title: 'Create  @.lower:resources.bankAccount.name',
                body: 'Contact our support and create your @.lower:resources.bankAccount.name',
                mailSubject: '[23M.com] Create bank account ({customerNumber})',
                mailBody:
                    'Hello 23M-Support-Team %0D%0A%0D%0A I would like to create the following bank account...',
            },
        },
        contact: {
            name: 'Contact person | Contact persons',
            attributes: {
                is_authorized: 'Authorized',
                has_account: '@:resources.user.nameAlternative available',
            },
            actions: {
                add: 'Add @.lower:resources.contact.name',
            },
            emptyState: {
                title: 'Create @.lower:resources.contact.name',
            },
            messages: {
                canNotDeleteYourself: "You can't delete yourself.",
                deactivateConfirmation:
                    'Do you really want to deactivate this @.lower:resources.contact.name ?',
            },
        },
        customer: {
            name: 'Customer',
            attributes: {
                number: 'Customer number',
                name: 'Name',
                vat_id: 'VAT id',
                payment_type: 'Payment method',
                payment_due_days: 'Payment term',
                receipt_mail_address: 'Invoice mail',
            },
            enums: {
                payment_type: {
                    bank_transfer: 'Bank transfer',
                    direct_debit: 'Direct debit',
                    paypal: 'Paypal',
                },
            },
            receiptMailAddressEmptyState: {
                title: 'Create @.lower:resources.customer.attributes.receipt_mail_address',
            },
        },
        user: {
            name: 'User',
            nameAlternative: 'Login',
            attributes: {
                name: 'Name',
                password: 'Password',
                recovery_codes: 'Recovery codes',
            },
            actions: {
                createAccount: {
                    label: 'Create  @.lower:resources.user.nameAlternative',
                    hint: 'An login will be created based on the contact information and an invitation email will be sent.',
                },
                confirmPassword: 'Confirm password',
                activateTwoFactorAuthentication: {
                    successMessage: 'Two factor authentication activated',
                },
                deactivateTwoFactorAuthentication: {
                    successMessage: 'Two factor authentication deactivated',
                },
            },
        },
        order: {
            name: 'Order',
            attributes: {
                number: 'Order number',
                status: 'Status',
                type: 'Order type',
                completed_at: 'Completed at',
                approved_at: 'Approved at',
                planned_start_at: 'Planned to',
                start_at: 'Start date',
                end_at: 'Terminated at',
                setup_price: 'NRC',
                monthly_price: 'MRC',
                price: 'Price',
                cancelation_period: 'Cancellation period',
                extension_period: 'Extension',
                min_order_duration: 'Minimum duration',
            },
            enums: {
                status: {
                    draft: 'Draft',
                    completed: 'Offer',
                    approved: 'Approved',
                    due: 'Due',
                    active: 'Active',
                    canceled: 'Terminated',
                    terminated: 'Terminated',
                    rejected: 'Rejected',
                },
                decoratedStatus: {
                    startAt: 'Planned start on: {date}',
                    startsShortly: 'Starts shortly',
                    endAt: 'Terminated on {date}',
                },
                type: {
                    dedicated: 'Dedicated Server',
                    rent: 'Rent',
                    colocation: 'Colocation',
                    service: 'Service',
                    cloudserver: 'Cloud Server',
                    cloud: 'Cloud',
                    ip: 'Ip',
                    s3: 'S3 Object Storage',
                    domain_robot: 'Domain',
                    network: 'Network',
                },
            },
            ticket: {
                subject: '[@:resources.order.name {orderNumber}] ',
            },
        },
        orderItem: {
            name: 'Order item | Order items',
            attributes: {
                category: 'Category',
                type: 'Type',
                description: 'Description',
            },
            enums: {
                category: {
                    device: 'Hardware',
                    ram: 'RAM',
                    storage: 'Storage',
                    gpu: 'GPU',
                    network: 'Network',
                    service: 'Service',
                    ip: 'IP address | IP addresses',
                    s3: 'S3 Object Storage',
                    power: 'Power',
                    unknown: 'Unknown',
                    vpn: 'VPN',
                    peripheral: 'Accessory',
                },
            },
            actions: {
                ipmiRequest: 'Show IPMI',
                showLanTrafficGraph: 'LAN traffic',
            },
            errors: {
                ipmiRequest: 'An error occurred during the IPMI query. Please try again later.',
            },
        },
        ipUsageAddress: {
            name: '',
            attributes: {
                ip: 'IP address | IP addresses',
                order_number: '@:resources.order.name',
                dns_name: 'rDNS name',
                customer_reference: 'Customer reference',
            },
        },
        ipUsageNetwork: {
            name: '',
            attributes: {
                ip: 'IP network | IP networks',
                order_number: '@:resources.order.name',
                network_ips: 'rDNS entries',
                has_dns_management: '@:resources.ipUsageAddress.attributes.has_dns_management',
                customer_reference: '@:resources.ipUsageAddress.attributes.customer_reference',
            },
        },
        networkIp: {
            name: '',
            attributes: {
                ip: 'IP address | IP addresses',
                mask: 'Subnet mask',
                dns_name: 'rDNS Name',
            },
        },
        vpnIpUsage: {
            name: 'VPN',
            attributes: {
                ip: 'IP address | IP addresses',
                order_number: '@:resources.order.name',
            },
        },
        deviceUsage: {
            name: 'Server | Servers',
            attributes: {
                device_unit_name: 'Name',
                device_unit_old_name: 'Legacy name',
                device_name: 'Type',
                cpu: 'CPU',
                order_number: 'Order',
                customer_reference: '@:resources.ipUsageAddress.attributes.customer_reference',
                device_unit_component_unit_descriptions: 'Components',
                device_unit_netbox_item_rack_name: 'Rack',
                device_unit_netbox_item_rack_room: 'Room',
            },
            actions: {
                createCustomerReference:
                    'Create @.lower:resources.deviceUsage.attributes.customer_reference',
                editCustomerReference:
                    'Edit @.lower:resources.deviceUsage.attributes.customer_reference ',
            },
            ticket: {
                subject: '[@:resources.deviceUsage.name {name}] ',
            },
        },
        rentUsage: {
            name: 'Rental | Rentals',
            attributes: {
                device_unit_name: '@:resources.deviceUsage.attributes.device_unit_name',
                device_name: '@:resources.deviceUsage.attributes.device_name',
                order_number: '@:resources.deviceUsage.attributes.order_number',
                device_unit_serial: 'Serial',
                device_type: 'Device type',
            },
            ticket: {
                subject: '[@:resources.rentUsage.name {name}] ',
            },
        },
        colocationUsage: {
            name: '',
            attributes: {
                id: 'ID',
                rack_name: 'Rack',
                rack_room: 'Room',
                order_number: '@:resources.order.name',
                order_colocation_item_customer_reference:
                    '@:resources.ipUsageAddress.attributes.customer_reference',
                order_colocation_item_colocation_name: 'Product',
                order_colocation_item_order_feed_item_current_power_consumption:
                    'Power consumption',
            },
            actions: {
                createCustomerReference:
                    'Create @.lower:resources.colocationUsage.attributes.order_colocation_item_customer_reference',
                editCustomerReference:
                    'Edit @.lower:resources.colocationUsage.attributes.order_colocation_item_customer_reference',
            },
            ticket: {
                subject: '[@:resources.order.name {name}] ',
            },
        },
        orderTrafficItem: {
            name: 'Traffic',
            attributes: {
                pricePerUnit: 'Per additional {unit} {price}',
            },
            actions: {
                showWanTrafficGraph: 'WAN traffic',
            },
        },
        orderS3Item: {
            name: 'S3 Object Storage',
            attributes: {
                username: 'User ID',
                pricing: '{pricePerUnit} per GB / per month',
            },
            actions: {
                managementInterface: 'Open',
                editPassword: 'Edit password',
            },
        },
        orderCloudItem: {
            name: 'Cloud',
            attributes: {
                account_name: 'Username',
                password: 'Password',
                domain_name: 'Domain',
            },
            actions: {
                openManagementInterface: 'Open',
                requestPassword: {
                    label: 'Request password',
                    confirm: 'Request password once',
                    hint: 'As your security is important to us, you can only request your password once. Please keep it safe (e.g. in a password manager). If you have any problems, our support team will be happy to help.',
                    success: 'Password successfully requested',
                    disabled: 'Has already been requested',
                },
            },
        },
        orderIpItem: {
            name: 'IP addresses',
        },
        orderDomainItem: {
            name: 'Domain Services',
            attributes: {
                domain_username: 'Username',
            },
            actions: {
                openDomainRobot: 'Open domain robot',
            },
        },
        orderFeedItem: {
            attributes: {
                fuse_protection: 'Protection per feed {fuseProtection} A',
            },
        },
        orderPowerItem: {
            pricing: '{pricePerUnit} per kWh',
        },
        orderColocationItem: {
            attributes: {
                rack_name: 'Rack',
            },
        },
        orderCloudserverItem: {
            name: 'Cloud Server',
        },
        orderLockerItem: {
            name: 'Locker',
            attributes: {
                locker_room: 'Room',
                locker_name: 'Cabinet',
                compartment: 'Compartment',
            },
        },
        orderVpnItem: {
            name: '{ count } { type } | { count } { type }s',
        },
        orderCrossConnectItem: {
            name: 'Cross Connect',
            attributes: {
                type: 'Type',
                usage_interface_name: 'Name',
                netbox_cross_connect_name: 'Identifier',
                netbox_rack_name: 'Rack',
                netbox_rack_room: 'Room',
                connected_item_order_number: 'Connected Order',
            },
        },
        invoice: {
            name: 'Invoice | Invoices',
            attributes: {
                number: 'Invoice number',
                status: 'Status',
                date: 'Invoice date',
                due_on: 'Due date',
                total_net: 'Net',
                total_gross: 'Gross',
            },
            enums: {
                status: {
                    open: 'Open',
                    paid: 'Paid',
                },
            },
        },
        ticket: {
            name: 'Ticket | Tickets',
            attributes: {
                subject: 'Subject',
                id: 'ID',
                created_at: 'Created',
                updated_at: 'Last activity',
                status: 'Status',
                topic: 'Topic',
                description: 'Description',
            },
            enums: {
                status: {
                    new: 'Open',
                    open: 'Open',
                    pending: 'Awaiting your reply',
                    closed: 'Solved',
                    solved: 'Solved',
                },
                topic: {
                    sales: 'Product or quotation enquiry',
                    finance: 'Question about a settlement',
                    technic: 'Technical support (during business hours)',
                    emergency: 'Technical emergency (outside business hours)',
                    miscellaneous: 'Miscellaneous',
                },
            },
            actions: {
                selectStatus: 'Any',
                create: 'Create @:resources.ticket.name',
            },
        },
        ticketComment: {
            name: 'Comment | Comments',
        },
    },
    messages: {
        info: {
            newOffer: 'You have a new offer',
            empty: 'No data found',
        },
        success: {
            saved: 'Saved successfully',
        },
        thanks: {
            title: 'Thank you for your trust',
            description: 'We will start with the implementation immediately.',
        },
    },
    forms: {
        optional: 'Optional',
        addressDach: {
            street: {
                label: 'Street',
                placeholder: '',
            },
            number: {
                label: 'House number',
                placeholder: '',
            },
            country: {
                label: 'Country',
            },
            zip: {
                label: 'Zip code',
                placeholder: '',
            },
            city: {
                label: 'City',
                placeholder: '',
            },
            additional: {
                label: 'Additional',
                placeholder: '',
            },
        },
        addressNonDach: {
            street: {
                label: 'Street & house number',
                placeholder: '',
            },
            country: {
                label: 'Country',
            },
            city: {
                label: 'Zip code & city',
                placeholder: '',
            },
            additional: {
                label: 'Additional',
                placeholder: '',
            },
        },
        contact: {
            title: {
                label: 'Title',
                placeholder: '',
            },
            first_name: {
                label: 'First name',
                placeholder: '',
            },
            last_name: {
                label: 'Last name',
                placeholder: '',
            },
            gender: {
                label: 'Salutation',
                options: {
                    m: 'Mr.',
                    f: 'Mrs.',
                    d: 'Neutral',
                },
            },
            email: {
                label: 'Mail',
                placeholder: '',
            },
            phone: {
                label: 'Phone',
                placeholder: '',
            },
            mobile: {
                label: 'Mobile',
                placeholder: '',
            },
            is_authorized: {
                label: 'Authorized',
            },
            is_creating_account: {
                label: 'Create a @.lower:resources.user.nameAlternative ?',
            },
        },
        updateUserPassword: {
            title: 'Change password',
            description: 'Update your password associated with your account.',
            password_current: {
                label: 'Current password',
                placeholder: '',
            },
            password: {
                label: 'New password',
                placeholder: '',
            },
            password_confirmation: {
                label: 'Confirm password',
                placeholder: '',
            },
        },
        twoFactorAuthentication: {
            title: 'Two factor authentication',
            description: 'Increase the security of your account with two factor authentication.',
            has_two_factor_authentication: {
                label: 'Authenticator app',
            },
            recovery_codes: {
                label: '@:resources.user.attributes.recovery_codes',
            },
        },
        sendFeedback: {
            message: {
                label: 'Your message',
                placeholder: 'What would you like us to know?',
            },
            submit: 'Send feedback',
        },
        ticket: {
            topic: {
                label: '@:resources.ticket.attributes.topic',
            },
            subject: {
                label: '@:resources.ticket.attributes.subject',
            },
            description: {
                label: '@:resources.ticket.attributes.description',
            },
            uploads: {
                label: 'Attachments',
            },
        },
        ticketComment: {
            comment: {
                placeholder: 'Add a @.lower:resources.ticketComment.name ...',
            },
        },
    },
};
