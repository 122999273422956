<template>
    <li class="relative flex gap-x-4">
        <div
            v-if="!isLast"
            class="absolute -bottom-6 left-0 top-0 flex w-12 justify-center"
        >
            <div class="w-px bg-gray-200"></div>
        </div>
        <div class="relative flex h-7 w-12 flex-none items-center justify-center bg-gray-100">
            <svg
                v-if="iconType === FeedItemIconType.GreenCheck"
                class="h-6 w-6 text-green"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
            >
                <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clip-rule="evenodd"
                />
            </svg>
            <div
                v-else
                class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"
            ></div>
        </div>
        <p class="flex-auto py-0.5 text-sm text-gray-500">
            <span class="text-gray-900">{{ title }}</span> {{ description }}
        </p>
        <time
            datetime="{{date}}"
            class="flex-none py-0.5 text-sm text-gray-500"
        >
            {{ diffInWords(date) }}
        </time>
    </li>
</template>

<script setup lang="ts">
import { useCoreSetup } from '../modules/your/composables/core';
import { FeedItemIconType } from '../types';

const { diffInWords } = useCoreSetup();

interface Props {
    title: string;
    description: string;
    date: string;
    iconType?: FeedItemIconType;
    isLast?: boolean;
}

withDefaults(defineProps<Props>(), {
    iconType: undefined,
    isLast: false,
});
</script>
