<template>
    <div
        :class="classObjectRoot"
        data-spec-class="alert"
    >
        <div class="flex">
            <div class="flex-shrink-0">
                <component
                    :is="getIcon()"
                    :class="classObjectIcon"
                    aria-hidden="true"
                />
            </div>
            <div class="ml-3">
                <h3
                    :class="classObjectMessage"
                    data-spec-class="alert-message"
                >
                    <slot />
                </h3>
                <div
                    :class="classObjectDescription"
                    v-if="!!$slots.description"
                    data-spec-class="alert-description"
                >
                    <slot name="description" />
                </div>
                <div
                    class="mt-4"
                    v-if="!!$slots.actions"
                >
                    <div class="-mx-2 -my-1.5 flex">
                        <slot name="actions" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue';
import {
    CheckCircleIcon,
    InformationCircleIcon,
    ExclamationTriangleIcon,
    XCircleIcon,
} from '@heroicons/vue/20/solid';
import { Functional, Size } from '../types';

interface Props {
    type?: Functional;
    size?: Size;
}

const props = withDefaults(defineProps<Props>(), {
    type: Functional.Warning,
    size: Size.Normal,
});

const classObjectRoot = computed(() => ({
    'rounded-md': true,
    'p-4': props.size === Size.Small,
    'px-10 py-7': props.size === Size.Normal,
    'bg-red-lighter border border-red': props.type === Functional.Error,
    'bg-orange-200 border border-orange-400': props.type === Functional.Warning,
    'bg-blue-300 border border-blue-400': props.type === Functional.Info,
    'bg-green-lighter border border-green': props.type === Functional.Success,
}));

const classObjectIcon = computed(() => ({
    'mt-[1px]': true,
    'h-6 w-6': props.size === Size.Small,
    'h-8 w-8': props.size === Size.Normal,
    'text-red': props.type === Functional.Error,
    'text-orange-700': props.type === Functional.Warning,
    'text-blue-700': props.type === Functional.Info,
    'text-green': props.type === Functional.Success,
}));

const classObjectMessage = computed(() => ({
    'text-medium': props.size === Size.Small,
    'text-xl': props.size === Size.Normal,
    'text-red': props.type === Functional.Error,
    'text-orange-700': props.type === Functional.Warning,
    'text-blue-700': props.type === Functional.Info,
    'text-blue': props.type === Functional.Success,
}));

const classObjectDescription = computed(() => ({
    'text-gray-700': true,
    'mt-1 text-xs': props.size === Size.Small,
    'mt-4': props.size === Size.Normal,
}));

function getIcon() {
    switch (props.type) {
        case Functional.Error:
            return XCircleIcon;
        case Functional.Warning:
            return ExclamationTriangleIcon;
        case Functional.Info:
            return InformationCircleIcon;
        case Functional.Success:
            return CheckCircleIcon;
        default:
            return ExclamationTriangleIcon;
    }
}
</script>
